import {
  IoPauseOutline,
  IoPencilOutline,
  IoPlayOutline,
  IoTimeOutline,
} from "react-icons/io5";
import { defineMessage } from "react-intl";
import generateFrontPath from "../libraries/utils/generateFrontPath";
import { AMENITIES_CATEGORY_LIST } from "./amenities";
import {
  POLICIES_POLICY_VALUE_DELETE,
  POLICIES_POLICY_VALUE_RESTORE,
  POLICIES_POLICY_VALUE_SOFT_DELETE,
} from "./policies";

export const CAMPAIGNS_TYPE_VALUE_THEMATIC = "thematic";
export const CAMPAIGNS_TYPE_VALUE_DESTINATION = "destination";

export const CAMPAIGNS_TYPES = {
  [CAMPAIGNS_TYPE_VALUE_THEMATIC]: {
    id: CAMPAIGNS_TYPE_VALUE_THEMATIC,
  },
  [CAMPAIGNS_TYPE_VALUE_DESTINATION]: {
    id: CAMPAIGNS_TYPE_VALUE_DESTINATION,
  },
};

/** @type {Record<import("../types/Campaign").CampaignType, {id: import("../types/Campaign").CampaignType, label: import("react-intl").MessageDescriptor, searchTermPlaceholder: import("react-intl").MessageDescriptor, searchUrl: string}>} */
export const CAMPAIGNS_DATA_FRONT = {
  [CAMPAIGNS_TYPE_VALUE_THEMATIC]: {
    id: CAMPAIGNS_TYPE_VALUE_THEMATIC,
    label: defineMessage({ defaultMessage: "Séjours à thèmes" }),
    searchTermPlaceholder: defineMessage({
      defaultMessage: "Rechercher une thématique",
    }),
    searchUrl: generateFrontPath({ id: "@front.thematics.search" }),
  },
  [CAMPAIGNS_TYPE_VALUE_DESTINATION]: {
    id: CAMPAIGNS_TYPE_VALUE_DESTINATION,
    label: defineMessage({ defaultMessage: "Top destinations" }),
    searchTermPlaceholder: defineMessage({
      defaultMessage: "Rechercher une destination",
    }),
    searchUrl: generateFrontPath({ id: "@front.destinations.search" }),
  },
};

export const CAMPAIGNS_TYPE_LIST = Object.values(CAMPAIGNS_TYPES);

export const campaignsTypeMessage = defineMessage({
  defaultMessage:
    "{type, select, thematic {thématique} destination {destination} other {{type}}}",
});

export const campaignsTypePluralMessage = defineMessage({
  defaultMessage:
    "{type, select, thematic {thématiques} destination {destinations} other {{type}}}",
});

export const campaignsTypePartitiveElisiveSingularMessage = defineMessage({
  defaultMessage:
    "{type, select, thematic {d'une thématique} destination {d'une destination} other {d'un/d'une {type}}}",
});

export const campaignsTypePartitiveSingularMessage = defineMessage({
  defaultMessage:
    "{type, select, thematic {de thématique} destination {de destination} other {d'un/d'une {type}}}",
});

export const campaignsTypePartitivePluralMessage = defineMessage({
  defaultMessage:
    "{type, select, thematic {des thématiques} destination {des destinations} other {des {type}}}",
});

export const campaignsTypeIndefiniteSingularMessage = defineMessage({
  defaultMessage:
    "{type, select, thematic {une thématique} destination {une destination} other {un/une {type}}}",
});

export const campaignsTypeDefiniteSingularMessage = defineMessage({
  defaultMessage:
    "{type, select, thematic {la thématique} destination {la destination} other {le/la {type}}}",
});

export const campaignsTypeDefinitePluralMessage = defineMessage({
  defaultMessage:
    "{type, select, thematic {les thématiques} destination {les destinations} other {les {type}}}",
});

export const campaignsTypeDemonstrativeSingularMessage = defineMessage({
  defaultMessage:
    "{type, select, thematic {cette thématique} destination {cette destination} other {les {type}}}",
});

export const campaignsTypeDemonstrativePluralMessage = defineMessage({
  defaultMessage:
    "{type, select, thematic {ces thématiques} destination {ces destinations} other {les {type}}}",
});

export const campaignsTypePossessiveSingularMessage = defineMessage({
  defaultMessage:
    "{type, select, thematic {votre thématique} destination {votre destination} other {votre {type}}}",
});

export const campaignsTypePossessivePluralMessage = defineMessage({
  defaultMessage:
    "{type, select, thematic {vos thématiques} destination {vos destinations} other {vos {type}}}",
});

// États

export const CAMPAIGNS_STATE_VALUE_DRAFT = "draft";
export const CAMPAIGNS_STATE_VALUE_PUBLISHED = "published";
export const CAMPAIGNS_STATE_VALUE_DISABLED = "disabled";
export const CAMPAIGNS_STATE_VALUE_PENDING = "pending"; // status virtuel n'existe pas en base

export const CAMPAIGNS_STATES = {
  [CAMPAIGNS_STATE_VALUE_DRAFT]: {
    id: CAMPAIGNS_STATE_VALUE_DRAFT,
    colors: ["gray.600", "gray.300"],
    color: "gray",
    icon: IoPencilOutline,
    enabledTab: true,
  },
  [CAMPAIGNS_STATE_VALUE_PENDING]: {
    id: CAMPAIGNS_STATE_VALUE_PENDING,
    colors: ["orange.600", "orange.300"],
    color: "purple",
    icon: IoTimeOutline,
    enabledTab: false,
  },
  [CAMPAIGNS_STATE_VALUE_PUBLISHED]: {
    id: CAMPAIGNS_STATE_VALUE_PUBLISHED,
    colors: ["green.600", "green.300"],
    color: "green",
    icon: IoPlayOutline,
    enabledTab: true,
  },
  [CAMPAIGNS_STATE_VALUE_DISABLED]: {
    id: CAMPAIGNS_STATE_VALUE_DISABLED,
    colors: ["purple.600", "purple.300"],
    color: "purple",
    icon: IoPauseOutline,
    enabledTab: true,
  },
};

export const CAMPAIGNS_STATE_LIST = Object.values(CAMPAIGNS_STATES);

export const campaignsStateMessage = defineMessage({
  defaultMessage: `{state, select,
    draft {brouillon}
    published {publié}
    pending {en attente de publication}
    disabled {désactivé}
    other {{state}}
  }`,
});

export const campaignsStateVerbMessage = defineMessage({
  defaultMessage:
    "{state, select, draft {passer en brouillon} published {publier} disabled {désactiver} other {{type}}}",
});

export const campaignsSearchTabMessage = defineMessage({
  defaultMessage:
    "{tab, select, all {Tous} draft {Brouillon} published {Publié} disabled {Désactivé} other {{tab}}}",
  description: "tab",
});

// Tris

export const CAMPAIGNS_SORT_VALUE_CREATED_ASC = "created_asc";
export const CAMPAIGNS_SORT_VALUE_CREATED_DESC = "created_desc";
export const CAMPAIGNS_SORT_VALUE_PUBLISHED_ASC = "published_asc";
export const CAMPAIGNS_SORT_VALUE_PUBLISHED_DESC = "published_desc";
export const CAMPAIGNS_SORT_VALUE_RANDOM = "rand";

export const CAMPAIGNS_SORTS = {
  [CAMPAIGNS_SORT_VALUE_CREATED_ASC]: {
    id: CAMPAIGNS_SORT_VALUE_CREATED_ASC,
    isPublic: false,
    isAdmin: true,
  },
  [CAMPAIGNS_SORT_VALUE_CREATED_DESC]: {
    id: CAMPAIGNS_SORT_VALUE_CREATED_DESC,
    isPublic: false,
    isAdmin: true,
  },
  [CAMPAIGNS_SORT_VALUE_PUBLISHED_ASC]: {
    id: CAMPAIGNS_SORT_VALUE_PUBLISHED_ASC,
    isPublic: false,
    isAdmin: true,
  },
  [CAMPAIGNS_SORT_VALUE_PUBLISHED_DESC]: {
    id: CAMPAIGNS_SORT_VALUE_PUBLISHED_DESC,
    isPublic: true,
    isAdmin: true,
  },
  [CAMPAIGNS_SORT_VALUE_RANDOM]: {
    id: CAMPAIGNS_SORT_VALUE_RANDOM,
    isPublic: false,
    isAdmin: false,
  },
};

export const CAMPAIGNS_SORT_LIST = Object.values(CAMPAIGNS_SORTS);

export const CAMPAIGNS_SORT_MESSAGE = defineMessage({
  defaultMessage:
    "{sort, select, created_asc {Date de création croissante} created_desc {Date de création décroissante} published_asc {Date de publication croissante} published_desc {Date de publication décroissante} other {{sort}}}",
});

export const CAMPAIGN_POLICIES_ACTIONS = {
  restore: {
    policies: [POLICIES_POLICY_VALUE_RESTORE],
  },
  delete: {
    somePolicies: [
      POLICIES_POLICY_VALUE_SOFT_DELETE,
      POLICIES_POLICY_VALUE_DELETE,
    ],
  },
};

export const CAMPAIGNS_SEO_CATEGORY_VALUE_CITY = "city";
export const CAMPAIGNS_SEO_CATEGORY_VALUE_DEPARTMENT = "department";
export const CAMPAIGNS_SEO_CATEGORY_VALUE_LEGACY_REGION = "legacy_region";

export const CAMPAIGNS_SEO_CATEGORIES = {
  [CAMPAIGNS_SEO_CATEGORY_VALUE_CITY]: {
    id: CAMPAIGNS_SEO_CATEGORY_VALUE_CITY,
  },
  [CAMPAIGNS_SEO_CATEGORY_VALUE_DEPARTMENT]: {
    id: CAMPAIGNS_SEO_CATEGORY_VALUE_DEPARTMENT,
  },
  [CAMPAIGNS_SEO_CATEGORY_VALUE_LEGACY_REGION]: {
    id: CAMPAIGNS_SEO_CATEGORY_VALUE_LEGACY_REGION,
  },
};

export const CAMPAIGNS_SEO_CATEGORY_LIST = Object.values(
  CAMPAIGNS_SEO_CATEGORIES,
);

export const campaignsSeoCategoryMessage = defineMessage({
  defaultMessage:
    "{category, select, city {ville} department {département} legacy_region {ancienne région} other {{category}}}",
});

export const CAMPAIGNS_SEO_TYPE_VALUE_LOCATION = "location";
export const CAMPAIGNS_SEO_TYPE_VALUE_COTTAGE = "cottage";
export const CAMPAIGNS_SEO_TYPE_VALUE_GUEST_HOUSE = "guest_house";

export const CAMPAIGNS_SEO_TYPES = {
  [CAMPAIGNS_SEO_TYPE_VALUE_LOCATION]: {
    id: CAMPAIGNS_SEO_TYPE_VALUE_LOCATION,
  },
  [CAMPAIGNS_SEO_TYPE_VALUE_COTTAGE]: {
    id: CAMPAIGNS_SEO_TYPE_VALUE_COTTAGE,
  },
  [CAMPAIGNS_SEO_TYPE_VALUE_GUEST_HOUSE]: {
    id: CAMPAIGNS_SEO_TYPE_VALUE_GUEST_HOUSE,
  },
};

export const CAMPAIGNS_SEO_TYPE_LIST = Object.values(CAMPAIGNS_SEO_TYPES);

export const campaignsSeoTypeMessage = defineMessage({
  defaultMessage:
    "{type, select, location {location de vacances} cottage {gîte} guest_house {chambre d'hôte} other {{type}}}",
});

export const CAMPAIGNS_LOCALIZATIONS_TYPE_VALUE_POINT = "point";
export const CAMPAIGNS_LOCALIZATIONS_TYPE_VALUE_POLYGON = "polygon";

export const CAMPAIGNS_LOCALIZATIONS_TYPES = {
  [CAMPAIGNS_LOCALIZATIONS_TYPE_VALUE_POINT]: {
    id: CAMPAIGNS_LOCALIZATIONS_TYPE_VALUE_POINT,
  },
  [CAMPAIGNS_LOCALIZATIONS_TYPE_VALUE_POLYGON]: {
    id: CAMPAIGNS_LOCALIZATIONS_TYPE_VALUE_POLYGON,
  },
};

export const CAMPAIGNS_LOCALIZATIONS_TYPE_LIST = Object.values(
  CAMPAIGNS_LOCALIZATIONS_TYPES,
);

export const campaignsLocalizationsTypeMessage = defineMessage({
  defaultMessage:
    "{type, select, point {Point} polygon {Polygone} other {{type}}}",
});

export const CAMPAIGNS_ATTRIBUTES_RANKING_KEYS = Array.from(Array(6).keys());

export const campaignsAttributesKeysRankingMessage = defineMessage({
  defaultMessage:
    "{key, plural, =0 {Pas de classement} one {# clé} other {# clés}}",
});

export const campaignsAttributesMessage = defineMessage({
  defaultMessage: `{name,
    select,
    checkin {Date d'arrivée}
    checkout {Date de départ}
    min_checkin {Date d'arrivée minimale}
    max_checkin {Date d'arrivée maximale}
    min_checkout {Date de départ minimale}
    max_checkout {Date de départ maximale}
    min_duration {Durée minimale}
    max_duration {Durée maximale}
    adults {Adultes}
    children {Enfants}
    babies {Bébés}
    pets {Animaux}
    type {Type}
    min_price {Prix minimum}
    max_price {Prix maximum}
    promotion {Promotion}
    highlight {Mise en avant}
    new {Nouveau}
    bedrooms {Chambres}
    beds {Lits}
    bathrooms {Salle d'eau}
    types {Type d'hébergement}
    label_ranking {Classement}
    bookable_online {Réservable en ligne}
    amenity_ids {Équipements}
      other {{name}}}`,
});

/** @type {{name: import("../types/Campaign").CampaignAttributeName, value: any, type: "date" | "number" | "boolean" | "array" }[]} */
export const CAMPAIGNS_ATTRIBUTES_DEFAULT_VALUES = [
  {
    name: "checkin",
    value: "",
    type: "date",
  },
  {
    name: "checkout",
    value: "",
    type: "date",
  },
  {
    name: "min_checkin",
    value: "",
    type: "date",
  },
  {
    name: "max_checkin",
    value: "",
    type: "date",
  },
  {
    name: "min_checkout",
    value: "",
    type: "date",
  },
  {
    name: "max_checkout",
    value: "",
    type: "date",
  },
  {
    name: "min_duration",
    value: "",
    type: "date",
  },
  {
    name: "max_duration",
    value: "",
    type: "date",
  },
  {
    name: "adults",
    value: "",
    type: "number",
  },
  {
    name: "children",
    value: "",
    type: "number",
  },
  {
    name: "babies",
    value: "",
    type: "number",
  },
  {
    name: "pets",
    value: "",
    type: "number",
  },
  {
    name: "type",
    value: "",
    type: "number",
  },
  {
    name: "min_price",
    value: "",
    type: "number",
  },
  {
    name: "max_price",
    value: "",
    type: "number",
  },
  {
    name: "promotion",
    value: false,
    type: "boolean",
  },
  {
    name: "highlight",
    value: false,
    type: "boolean",
  },
  {
    name: "new",
    value: false,
    type: "boolean",
  },
  {
    name: "bookable_online",
    value: false,
    type: "boolean",
  },
  {
    name: "allow_pets",
    value: false,
    type: "boolean",
  },
  {
    name: "last_minute",
    value: false,
    type: "boolean",
  },
  {
    name: "bedrooms",
    value: "",
    type: "number",
  },
  {
    name: "beds",
    value: "",
    type: "number",
  },
  {
    name: "bathrooms",
    value: "",
    type: "number",
  },
  {
    name: "label_ranking",
    value: "",
    type: "number",
  },
  {
    name: "types",
    value: [],
    type: "array",
  },
];

export const CAMPAIGNS_AMENITIES_ATTRIBUTES_DEFAULT_VALUES =
  AMENITIES_CATEGORY_LIST.map((category) => ({
    name: `amenity_ids_${category.id}`,
    value: [],
    type: "array",
  }));

export const CAMPAIGNS_ATTRIBUTES_DEFAULT_VALUES_ADMIN = [
  ...CAMPAIGNS_ATTRIBUTES_DEFAULT_VALUES,
  ...CAMPAIGNS_AMENITIES_ATTRIBUTES_DEFAULT_VALUES,
];

export const CAMPAIGNS_ATTRIBUTES_VIEW_ADMIN = [
  ...CAMPAIGNS_ATTRIBUTES_DEFAULT_VALUES,
  {
    // Amenities are stored this way in the campaign attributes
    name: "amenity_ids",
    value: [],
    type: "array",
  },
];

export const CAMPAIGNS_IS_FEDERAL_VALUE_YES = 1;
export const CAMPAIGNS_IS_FEDERAL_VALUE_NO = 0;

export const CAMPAIGNS_IS_FEDERAL = {
  [CAMPAIGNS_IS_FEDERAL_VALUE_YES]: {
    id: CAMPAIGNS_IS_FEDERAL_VALUE_YES,
  },
  [CAMPAIGNS_IS_FEDERAL_VALUE_NO]: {
    id: CAMPAIGNS_IS_FEDERAL_VALUE_NO,
  },
};

export const CAMPAIGNS_IS_FEDERAL_LIST = Object.values(CAMPAIGNS_IS_FEDERAL);

export const campaignIsFederalMessage = defineMessage({
  defaultMessage: `{isFederal, select,
    1 {oui}
    0 {non}
    other {{isFederal}}
  }`,
});
