/* Autorisations */

export const AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_VIEW_ANY =
  "countries.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_VIEW =
  "countries.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_CREATE =
  "countries.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_UPDATE =
  "countries.update";

// Environments
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_VIEW_ANY =
  "environments.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_VIEW =
  "environments.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_UPDATE =
  "environments.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_CREATE =
  "environments.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_UPDATE_RECORDS_ACCOUNTS =
  "environments.update_records_accounts";

// Amenities
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_AMENITIES_VIEW_ANY =
  "amenities.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_AMENITIES_VIEW =
  "amenities.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_AMENITIES_UPDATE =
  "amenities.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_AMENITIES_CREATE =
  "amenities.create";

// Compositions
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_COMPOSITIONS_VIEW_ANY =
  "compositions.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_COMPOSITIONS_VIEW =
  "compositions.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_COMPOSITIONS_UPDATE =
  "compositions.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_COMPOSITIONS_CREATE =
  "compositions.create";

// Users/Groups
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_VIEW_ANY =
  "users.groups.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_VIEW =
  "users.groups.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_CREATE =
  "users.groups.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_UPDATE =
  "users.groups.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_DELETE =
  "users.groups.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_AUTOCOMPLETE =
  "users.groups.autocomplete";

// Users/Admins
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_VIEW_ANY =
  "users.admins.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_VIEW =
  "users.admins.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_CREATE =
  "users.admins.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_UPDATE =
  "users.admins.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_DELETE =
  "users.admins.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_PASSWORD_RESETS =
  "users.admins.password_resets";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_ACCESS_TOKENS =
  "users.admins.access_tokens";

// Users/Customers/Travelers
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_CUSTOMERS_TRAVELERS_VIEW_ANY =
  "users.customers.travelers.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_TRAVELERS_ADMINS_VIEW =
  "users.customers.travelers.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_TRAVELERS_ADMINS_CREATE =
  "users.customers.travelers.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_TRAVELERS_ADMINS_UPDATE =
  "users.customers.travelers.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_TRAVELERS_ADMINS_DELETE =
  "users.customers.travelers.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_TRAVELERS_ADMINS_EXPORT =
  "users.customers.travelers.export";

// Users/Customers
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_CUSTOMERS_VIEW_ANY =
  "users.customers.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_CUSTOMERS_VIEW =
  "users.customers.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_CUSTOMERS_RENTALS_WHITE_LABEL =
  "users.customers.rentals_white_label";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_CUSTOMERS_UPDATE =
  "users.customers.update";

export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_CUSTOMERS_CREATE =
  "users.customers.create";

export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_CUSTOMERS_EXPORT =
  "users.customers.export";

// Customers/Contacts
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_VIEW_ANY =
  "customers.contacts.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_VIEW =
  "customers.contacts.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_CREATE =
  "customers.contacts.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_UPDATE =
  "customers.contacts.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_DELETE =
  "customers.contacts.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_SOFT_DELETE =
  "customers.contacts.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_RESTORE =
  "customers.contacts.restore";

// Customers/Contacts/Categories
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_CATEGORIES_VIEW_ANY =
  "customers.contacts.categories.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_CATEGORIES_VIEW =
  "customers.contacts.categories.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_CATEGORIES_CREATE =
  "customers.contacts.categories.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_CATEGORIES_UPDATE =
  "customers.contacts.categories.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_CATEGORIES_DELETE =
  "customers.contacts.categories.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_CATEGORIES_SOFT_DELETE =
  "customers.contacts.categories.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_CATEGORIES_RESTORE =
  "customers.contacts.categories.restore";

// Customers/Contacts/Categories/CustomAttributes
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_CATEGORIES_CUSTOM_ATTRIBUTES_VIEW_ANY =
  "customers.contacts.categories.custom_attributes.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_CATEGORIES_CUSTOM_ATTRIBUTES_VIEW =
  "customers.contacts.categories.custom_attributes.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_CATEGORIES_CUSTOM_ATTRIBUTES_CREATE =
  "customers.contacts.categories.custom_attributes.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_CATEGORIES_CUSTOM_ATTRIBUTES_UPDATE =
  "customers.contacts.categories.custom_attributes.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_CATEGORIES_CUSTOM_ATTRIBUTES_DELETE =
  "customers.contacts.categories.custom_attributes.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_CATEGORIES_CUSTOM_ATTRIBUTES_SOFT_DELETE =
  "customers.contacts.categories.custom_attributes.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_CATEGORIES_CUSTOM_ATTRIBUTES_RESTORE =
  "customers.contacts.categories.custom_attributes.restore";

// Posts
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_VIEW_ANY =
  "posts.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_VIEW = "posts.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CREATE = "posts.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_UPDATE = "posts.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_SOFT_DELETE =
  "posts.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_DELETE = "posts.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_RESTORE = "posts.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_PUBLISH = "posts.publish";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_DISABLE = "posts.disable";

// Posts/Revisions
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_VIEW_ANY =
  "posts.revisions.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_VIEW =
  "posts.revisions.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_UPDATE =
  "posts.revisions.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_DELETE =
  "posts.revisions.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_SOFT_DELETE =
  "posts.revisions.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_RESTORE =
  "posts.revisions.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_REVIEW =
  "posts.revisions.review";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_REQUEST_REVIEW =
  "posts.revisions.request_review";

// Posts/Categories
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_VIEW_ANY =
  "posts.categories.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_VIEW =
  "posts.categories.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_CREATE =
  "posts.categories.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_UPDATE =
  "posts.categories.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_SOFT_DELETE =
  "posts.categories.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_DELETE =
  "posts.categories.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_RESTORE =
  "posts.categories.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_UPDATE_TREE =
  "posts.categories.update_tree";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_VIEW_TREE =
  "posts.categories.view_tree";

// Files
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_VIEW_ANY =
  "files.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_VIEW = "files.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_CREATE = "files.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_UPDATE = "files.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_DELETE = "files.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_SOFT_DELETE =
  "files.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_RESTORE = "files.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_DOWNLOAD =
  "files.download";

// Menus
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_VIEW_ANY =
  "menus.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_UPDATE = "menus.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_UPDATE_ITEMS =
  "menus.update_items";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_VIEW = "menus.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_DELETE = "menus.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_SOFT_DELETE =
  "menus.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_RESTORE = "menus.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_CREATE = "menus.create";

// Departments
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_DEPARTMENTS_VIEW_ANY =
  "departments.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_DEPARTMENTS_VIEW =
  "departments.view";

// Regions
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_REGIONS_VIEW_ANY =
  "regions.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_REGIONS_VIEW = "regions.view";

// Cities
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CITIES_VIEW_ANY =
  "cities.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CITIES_VIEW = "cities.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CITIES_CREATE = "cities.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CITIES_UPDATE = "cities.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CITIES_DELETE = "cities.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CITIES_SOFT_DELETE =
  "cities.soft_delete";

// account records
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ACCOUNTS_RECORDS_EXPORTS_VALUE_VIEW_ANY =
  "accounts_records_exports.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ACCOUNTS_RECORDS_EXPORTS_DOWNLOAD_VALUE_VIEW_ANY =
  "accounts_records_exports.download";

// hotels
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_VIEW_ANY =
  "hotels.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_VIEW = "hotels.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_CREATE = "hotels.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_EXPORT = "hotels.export";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_UPDATE = "hotels.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_AUTOCOMPLETE =
  "hotels.autocomplete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_UPDATE_PARTNER_CANCELLATION =
  "hotels.update_partner_cancellation";

// hotels contacts
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_CONTACTS_VIEW_ANY =
  "hotels.contacts.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_CONTACTS_VIEW =
  "hotels.contacts.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_CONTACTS_CREATE =
  "hotels.contacts.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_CONTACTS_UPDATE =
  "hotels.contacts.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_CONTACTS_DELETE =
  "hotels.contacts.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_CONTACTS_SOFT_DELETE =
  "hotels.contacts.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_CONTACTS_RESTORE =
  "hotels.contacts.restore";

// rooms availabilities
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_AVAILABILITIES_VIEW_ANY =
  "rooms.availabilities.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_AVAILABILITIES_BATCH =
  "rooms.availabilities.batch";

// rooms availabilities remotes
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_AVAILABILITIES_REMOTES_EXPORT =
  "rooms.availabilities.remotes.export";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_AVAILABILITIES_REMOTES_VIEW_ANY =
  "rooms.availabilities.remotes.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_AVAILABILITIES_REMOTES_VIEW =
  "rooms.availabilities.remotes.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_AVAILABILITIES_REMOTES_UPDATE =
  "rooms.availabilities.remotes.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_AVAILABILITIES_REMOTES_CREATE =
  "rooms.availabilities.remotes.create";

// rooms
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_EXPORT_REMOTE =
  "rooms.export_remote";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_VIEW_ANY =
  "rooms.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_VIEW = "rooms.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_EXPORT = "rooms.export";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_UPDATE = "rooms.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_AUTOCOMPLETE =
  "rooms.autocomplete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_CREATE = "rooms.create";

// rooms service
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_SERVICES_VIEW_ANY =
  "rooms.services.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_SERVICES_VIEW =
  "rooms.services.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_SERVICES_UPDATE =
  "rooms.services.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_SERVICES_CREATE =
  "rooms.services.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_SERVICES_BATCH_ROOMS =
  "rooms.services.batch_rooms";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_SERVICES_UPDATE_SUBSCRIBER =
  "rooms.services.update_subscriber";

// rooms discount
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_DISCOUNTS_VIEW_ANY =
  "rooms.discounts.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_DISCOUNTS_VIEW =
  "rooms.discounts.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_DISCOUNTS_UPDATE =
  "rooms.discounts.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_DISCOUNTS_CREATE =
  "rooms.discounts.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_DISCOUNTS_BATCH_ROOMS =
  "rooms.discounts.batch_rooms";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_DISCOUNTS_UPDATE_SUBSCRIBER =
  "rooms.discounts.update_subscriber";

// rooms document
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_DOCUMENTS_VIEW_ANY =
  "rooms.documents.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_DOCUMENTS_VIEW =
  "rooms.documents.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_DOCUMENTS_UPDATE =
  "rooms.documents.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_DOCUMENTS_CREATE =
  "rooms.documents.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_DOCUMENTS_UPDATE_SUBSCRIBER =
  "rooms.documents.update_subscriber";

// rooms seasons (Tarifs)
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_SEASONS_VIEW_ANY =
  "rooms.seasons.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_SEASONS_VIEW =
  "rooms.seasons.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_SEASONS_UPDATE =
  "rooms.seasons.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_SEASONS_CREATE =
  "rooms.seasons.create";

export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_SEASONS_DUPLICATE =
  "rooms.seasons.duplicate";

export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_SEASONS_POSITION =
  "rooms.seasons.position";

// rooms compositions
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_COMPOSITIONS_VIEW_ANY =
  "rooms.compositions.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_COMPOSITIONS_VIEW =
  "rooms.compositions.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_COMPOSITIONS_UPDATE =
  "rooms.compositions.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_COMPOSITIONS_CREATE =
  "rooms.compositions.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_COMPOSITIONS_SOFT_DELETE =
  "rooms.compositions.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_COMPOSITIONS_DELETE =
  "rooms.compositions.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_COMPOSITIONS_RESTORE =
  "rooms.compositions.restore";

// rooms pictures
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_PICTURES_CREATE =
  "rooms.pictures.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_PICTURES_DELETE =
  "rooms.pictures.delete";

// rooms pictures categories
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_PICTURES_CATEGORIES_VIEW_ANY =
  "rooms.pictures.categories.view_any";

// room rankings
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_RANKINGS_VIEW_ANY =
  "rooms.rankings.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_RANKINGS_VIEW =
  "rooms.rankings.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_RANKINGS_UPDATE =
  "rooms.rankings.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_RANKINGS_CREATE =
  "rooms.rankings.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_RANKINGS_DELETE =
  "rooms.rankings.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_RANKINGS_SOFT_DELETE =
  "rooms.rankings.soft_delete";

// deliberations
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_DELIBERATIONS_VIEW_ANY =
  "deliberations.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_DELIBERATIONS_CREATE =
  "deliberations.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_DELIBERATIONS_VIEW =
  "deliberations.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_DELIBERATIONS_UPDATE =
  "deliberations.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_DELIBERATIONS_DELETE =
  "deliberations.delete";

// notifications
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_NOTIFICATIONS_VIEW_ANY =
  "notifications.view_any";

// payments
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_VIEW_ANY =
  "payments.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_EXPORT =
  "payments.export";

// payments remittances
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_REMITTANCES_VIEW_ANY =
  "payments.remittances.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_REMITTANCES_VIEW =
  "payments.remittances.view";

export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_REMITTANCES_CREATE =
  "payments.remittances.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_REMITTANCES_UPDATE =
  "payments.remittances.update";

// payments sepa
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_SEPAS_VIEW_ANY =
  "payments.sepas.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_SEPAS_EXPORT =
  "payments.sepas.export";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_SEPAS_VIEW =
  "payments.sepas.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_SEPAS_EXPORT_DOWNLOAD =
  "payments.sepas.export_download";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_SEPAS_DOWNLOAD =
  "payments.sepas.download";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_SEPAS_FINISH =
  "payments.sepas.finish";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_SEPAS_DELETE =
  "payments.sepas.delete";

// payments sepa transactions
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_SEPAS_TRANSACTIONS_VIEW_ANY =
  "payments.sepas.transactions.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_SEPAS_TRANSACTIONS_EXPORT =
  "payments.sepas.transactions.export";

// partners
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PARTNERS_VIEW_ANY =
  "partners.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PARTNERS_VIEW = "partners.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PARTNERS_UPDATE =
  "partners.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PARTNERS_ATTACH =
  "partners.attach";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PARTNERS_CREATE =
  "partners.create";

// metrics
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_METRICS_VIEW_ANY =
  "metrics.view_any";

// bookings
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_BOOKINGS_VIEW = "bookings.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_BOOKINGS_VIEW_ANY =
  "bookings.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_BOOKINGS_EXPORT =
  "bookings.export";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_BOOKINGS_CREATE =
  "bookings.create";

// carts
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_VIEW_ANY =
  "carts.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_VIEW = "carts.view";

// carts guests
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_GUESTS_CREATE =
  "carts.guests.create";

// carts booking issue
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_BOOKING_ISSUES_VIEW =
  "bookings.issues.view";

export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_BOOKING_ISSUES_UPDATE =
  "bookings.issues.update";

export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_BOOKING_ISSUES_VIEW_ANY =
  "bookings.issues.view_any";

// carts booking review

export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_BOOKING_REVIEW_VIEW_ANY =
  "bookings.reviews.view_any";

export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_BOOKING_REVIEW_VIEW =
  "bookings.reviews.view";

export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_BOOKING_REVIEW_ADMIN_MODERATION =
  "bookings.reviews.admin_moderation";

// carts booking messages

export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_BOOKING_MESSAGES_VIEW_ANY =
  "bookings.messages.view_any";

export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_BOOOKING_MESSAGES_TOPICS =
  "bookings.messages.topics";

// legacy
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_LEGACY_EXTRANET =
  "legacy.extranet";

// kiosc
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_EXTERNAL_KIOSC =
  "external.kiosc";

// insurance
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_INSURANCES_CONTRACTS_VIEW_ANY =
  "insurances.contracts.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_INSURANCES_EXPORT_VIEW_ANY =
  "insurances.contracts.exports.view_any";

// Subscriptions
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_VIEW_ANY =
  "subscriptions.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CREATE =
  "subscriptions.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_VIEW =
  "subscriptions.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_UPDATE =
  "subscriptions.update";

// Subscriptions customers
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_VIEW_ANY =
  "subscriptions.customers.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_VIEW =
  "subscriptions.customers.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_CANCEL =
  "subscriptions.customers.cancel";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_CREATE =
  "subscriptions.customers.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_BATCH_ROOMS =
  "subscriptions.customers.batch_rooms";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_EXPORT =
  "subscriptions.customers.export";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_UPDATE =
  "subscriptions.customers.update";

// invoices
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_VIEW_ANY =
  "invoices.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_DOWNLOAD =
  "invoices.download";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_EXPORT =
  "invoices.export";

// Slideshows
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SLIDESHOWS_VIEW_ANY =
  "slideshows.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SLIDESHOWS_VIEW =
  "slideshows.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SLIDESHOWS_DELETE =
  "slideshows.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SLIDESHOWS_SOFT_DELETE =
  "slideshows.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SLIDESHOWS_RESTORE =
  "slideshows.restore";

// Slideshows items
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SLIDESHOWS_ITEMS_VIEW_ANY =
  "slideshows.items.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SLIDESHOWS_ITEMS_VIEW =
  "slideshows.items.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SLIDESHOWS_ITEMS_CREATE =
  "slideshows.items.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SLIDESHOWS_ITEMS_UPDATE =
  "slideshows.items.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SLIDESHOWS_ITEMS_PUBLISH =
  "slideshows.items.publish";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SLIDESHOWS_ITEMS_DISABLE =
  "slideshows.items.disable";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SLIDESHOWS_ITEMS_DELETE =
  "slideshows.items.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SLIDESHOWS_ITEMS_SOFT_DELETE =
  "slideshows.items.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SLIDESHOWS_ITEMS_RESTORE =
  "slideshows.items.restore";

// Campaigns
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CAMPAIGNS_VIEW_ANY =
  "campaigns.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CAMPAIGNS_VIEW =
  "campaigns.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CAMPAIGNS_CREATE =
  "campaigns.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CAMPAIGNS_UPDATE =
  "campaigns.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CAMPAIGNS_SOFT_DELETE =
  "campaigns.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CAMPAIGNS_DELETE =
  "campaigns.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CAMPAIGNS_RESTORE =
  "campaigns.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CAMPAIGNS_PUBLISH =
  "campaigns.publish";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CAMPAIGNS_DISABLE =
  "campaigns.disable";

// Campaigns/Categories
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CAMPAIGNS_CATEGORIES_VIEW_ANY =
  "campaigns.categories.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CAMPAIGNS_CATEGORIES_VIEW =
  "campaigns.categories.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CAMPAIGNS_CATEGORIES_CREATE =
  "campaigns.categories.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CAMPAIGNS_CATEGORIES_UPDATE =
  "campaigns.categories.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CAMPAIGNS_CATEGORIES_SOFT_DELETE =
  "campaigns.categories.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CAMPAIGNS_CATEGORIES_DELETE =
  "campaigns.categories.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CAMPAIGNS_CATEGORIES_RESTORE =
  "campaigns.categories.restore";

// Users / Bank accounts
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_BANK_ACCOUNTS_VIEW_ANY =
  "users.bank_accounts.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_BANK_ACCOUNTS_CANCEL =
  "users.bank_accounts.cancel";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_BANK_ACCOUNTS_CREATE =
  "users.bank_accounts.create";

// Signatures
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SIGNATURES_DOWNLOAD =
  "signatures.download";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SIGNATURES_DOWNLOAD_AUDIT_TRAIL =
  "signatures.download_audit_trail";

// Redirections urls
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_REDIRECTIONS_URLS_VIEW_ANY =
  "redirections.urls.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_REDIRECTIONS_URLS_VIEW =
  "redirections.urls.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_REDIRECTIONS_URLS_CREATE =
  "redirections.urls.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_REDIRECTIONS_URLS_UPDATE =
  "redirections.urls.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_REDIRECTIONS_URLS_SOFT_DELETE =
  "redirections.urls.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_REDIRECTIONS_URLS_DELETE =
  "redirections.urls.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_REDIRECTIONS_URLS_RESTORE =
  "redirections.urls.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_REDIRECTIONS_URLS_HIT =
  "redirections.urls.hit";

// Highlights
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HIGHLIGHTS_VIEW_ANY =
  "highlights.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HIGHLIGHTS_VIEW =
  "highlights.view";

// Highlights
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PLANS_VIEW_ANY =
  "plans.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PLANS_VIEW = "plans.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PLANS_CREATE = "plans.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PLANS_UPDATE = "plans.update";

// Tasks
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_TASKS_VIEW_ANY =
  "tasks.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_TASKS_VIEW = "tasks.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_TASKS_RETRY = "tasks.retry";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_TASKS_CANCEL = "tasks.cancel";

// Tasks logs
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_TASKS_LOGS_VIEW_ANY =
  "tasks.logs.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_TASKS_LOGS_VIEW =
  "tasks.logs.view";

// Newsgroups
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_NEWSGROUPS_VIEW_ANY =
  "newsgroups.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_NEWSGROUPS_VIEW =
  "newsgroups.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_NEWSGROUPS_CREATE =
  "newsgroups.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_NEWSGROUPS_UPDATE =
  "newsgroups.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_NEWSGROUPS_SOFT_DELETE =
  "newsgroups.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_NEWSGROUPS_DELETE =
  "newsgroups.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_NEWSGROUPS_RESTORE =
  "newsgroups.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_NEWSGROUPS_EXPORT_SUBSCRIBERS =
  "newsgroups.export_subscribers";

// Newsgroups/Subscribers
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_NEWSGROUPS_SUBSCRIBERS_VIEW_ANY =
  "newsgroups.subscribers.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_NEWSGROUPS_SUBSCRIBERS_VIEW =
  "newsgroups.subscribers.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_NEWSGROUPS_SUBSCRIBERS_CREATE =
  "newsgroups.subscribers.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_NEWSGROUPS_SUBSCRIBERS_UPDATE =
  "newsgroups.subscribers.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_NEWSGROUPS_SUBSCRIBERS_DELETE =
  "newsgroups.subscribers.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_NEWSGROUPS_SUBSCRIBERS_ENABLE =
  "newsgroups.subscribers.enable";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_NEWSGROUPS_SUBSCRIBERS_EXPORT =
  "newsgroups.subscribers.export";
