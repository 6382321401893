/**
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 */

/**
 * Formatte le nombre en euros.
 * @type {import("react-intl").FormatNumberOptions}
 * @example 5,00 € (en français)
 */
export const CURRENCY_OPTIONS = {
  style: "currency",
  currency: "EUR",
};

/**
 * Formatte le nombre en pourcent.
 * @type {import("react-intl").FormatNumberOptions}
 * @example 5,00 %
 */
export const PERCENT_OPTIONS = {
  style: "percent",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

/**
 * Formatte le nombre en euros.
 * @type {import("react-intl").FormatNumberOptions}
 * @example 5 € (en français)
 */
export const NO_FRACTION_DIGIT_CURRENCY_OPTIONS = {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

/**
 * Formatte la date au format standard avec les heures.
 * @type {import("react-intl").FormatDateOptions}
 * @example 01/01/2020 14:00 (en français)
 */
export const SHORT_DATETIME_OPTIONS = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

/**
 * Formatte la date au format long.
 * @example Lundi 1 janvier (en français)
 */
export const LONG_DATE_WITHOUT_YEAR_OPTIONS = {
  day: "numeric",
  month: "long",
  weekday: "long",
};

/**
 * Formatte la date au format long.
 * @example Lundi 1 janvier 2020 (en français)
 * @type {import("react-intl").FormatDateOptions}
 */
export const LONG_DATE_OPTIONS = {
  day: "numeric",
  month: "long",
  year: "numeric",
  weekday: "long",
};

/**
 * Formatte la date avec seulement le mois et l’année.
 * @example Lundi 1 janvier 2020 (en français)
 */
export const YEAR_MONTH_DATE_OPTIONS = {
  month: "long",
  year: "numeric",
};

/**
 * Formatte la date au format long.
 * @type {import("react-intl").FormatDateOptions}
 * @example Lundi 1 janvier 2020 à 14:00 (en français)
 */
export const LONG_DATETIME_OPTIONS = {
  day: "numeric",
  month: "long",
  year: "numeric",
  weekday: "long",
  hour: "2-digit",
  minute: "2-digit",
};

/**
 * Formatte la date au format court.
 * @type {import("react-intl").FormatDateOptions}
 * @example 01/01/2020 (en français)
 */
export const SHORT_DATE_OPTIONS = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
};

/**
 * Formatte la date au format court.
 * @type {import("react-intl").FormatDateOptions}
 * @example 01/01/2020 (en français)
 */
export const SHORT_DATE_OPTIONS_WITHOUT_UTC = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  timeZone: "UTC", // Ensure the date is displayed as raw without considering local time zone
};

/**
 * Formatte l'heure au format court.
 * @type {import("react-intl").FormatDateOptions}
 * @example 09:18 (en français)
 */
export const SHORT_TIME_OPTIONS = {
  hour: "2-digit",
  minute: "2-digit",
};

/**
 * Formatte l'heure au format court.
 * @type {import("react-intl").FormatDateOptions}
 * @example 09:18 (en français)
 */
export const SHORT_DAY_MONTH_OPTIONS = {
  day: "2-digit",
  month: "2-digit",
};

/**
 * Formatte la date avec seulement l’année.
 * @type {import("react-intl").FormatDateOptions}
 */
export const YEAR_OPTIONS = {
  year: "numeric",
};

/**
 * Formatte la date avec seulement le mois et l’année.
 * @type {import("react-intl").FormatDateOptions}
 */
export const MONTH_YEAR_OPTIONS = {
  month: "long",
  year: "numeric",
};

/**
 * Formatte la jour au format long.
 * @example Lundi (en français)
 */
export const LONG_DAY_OPTIONS = {
  weekday: "long",
};
